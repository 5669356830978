import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/actions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import * as produitActions from "../redux/actions/Produit/index";
import { useNavigate, Link } from "react-router-dom";
import { FaShoppingCart, FaTrash, FaInfoCircle } from "react-icons/fa";
import { message } from "antd";

const Products = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const produits = useSelector((state) => state.produits);
  const [filter, setFilter] = useState(produits.list);

  let componentMounted = true;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
    message.success("Produit Bien Ajouté au Panier");
  };
  // const deleteProduct = (product) => {
  //   dispatch(produitActions.deleteProduit(product));
  // };

  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await fetch(
        "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produits"
      );
      dispatch(produitActions.fetchProduits());
      if (componentMounted) {
        setData(await response.clone().json());
        //setFilter(await response.json());
        setLoading(false);
      }

      return () => {
        componentMounted = false;
      };
    };

    getProducts();
  }, []);
  useEffect(() => {
    setFilter(produits.list);
  }, [produits]);

  const Loading = () => {
    return (
      <>
        <div className="col-12 py-5 text-center">
          <Skeleton height={40} width={560} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
        <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <Skeleton height={592} />
        </div>
      </>
    );
  };

  const filterProduct = (cat) => {
    const updatedList = produits.list.filter((item) => item.categorie === cat);
    setFilter(updatedList);
  };
  const ShowProducts = () => {
    return (
      <>
        <div className="buttons text-center py-5">
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => setFilter(produits.list)}
          >
            All
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Compléments")}
          >
            Compléments
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Protéines")}
          >
            Protéines
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Vitamines")}
          >
            Vitamines
          </button>
          <button
            className="btn btn-outline-dark btn-sm m-2"
            onClick={() => filterProduct("Snacks")}
          >
            Snacks
          </button>
        </div>

        {filter.map((item) => {
          return (
            <div
              id={item._id}
              key={item._id}
              className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4"
            >
              <div className="card text-center h-100 border border-gray-300 rounded-lg shadow-lg">
                <div
                  onClick={() => navigate("/product/" + item._id)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="image-container d-flex justify-content-center">
                    <img
                      style={{ height: 300, width: "100%" }}
                      src={item.url}
                      alt={item.name}
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title text-lg font-semibold">
                      {item.name.length > 12
                        ? item.name.substring(0, 12) + "..."
                        : item.name}
                    </h5>
                    <p className="card-text text-gray-700">
                      {item.description.length > 90
                        ? item.description.substring(0, 90) + "..."
                        : item.description}
                    </p>
                  </div>
                  <div className="gradient-line"></div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item lead bg-yellow-100">
                      <b>{item.prix} TND</b>
                    </li>
                  </ul>
                  <div className="gradient-line"></div>
                </div>
                <div className="card-body d-flex flex-wrap justify-content-center">
                  <Link
                    to={"/product/" + item._id}
                    className="btn btn-dark m-1 white-ss transition-colors duration-300 d-flex align-items-center"
                  >
                    <FaInfoCircle className="me-2" />
                    Produit
                  </Link>
                  <button
                    className="btn btn-dark m-1 white-ss transition-colors duration-300 d-flex align-items-center"
                    onClick={() => addProduct(item)}
                  >
                    <FaShoppingCart className="me-2" />
                    Ajouter au panier
                  </button>
                  {/* <button
                    className="btn btn-dark m-1 text-white bg-red-600 hover:bg-red-700 transition-colors duration-300 d-flex align-items-center"
                    onClick={() => deleteProduct(item)}
                  >
                    <FaTrash className="me-2" />
                    Supprimer
                  </button> */}
                </div>
              </div>
            </div>
          );
        })}

        {/* <div className="col-md-4 col-sm-6 col-xs-8 col-12 mb-4">
          <div className="card text-center h-100">
            <img
              className="card-img-top p-3"
              src={
                "https://ds-energy.s3.amazonaws.com/Gym/Products/Product4.jpg"
              }
              alt="Card"
              height={400}
            />
            <div className="card-body">
              <h5 className="card-title"></h5>
              <p className="card-text"></p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item lead">$ </li>
              
            </ul>
            <div className="card-body">
              <Link to={"/product/"} className="btn btn-dark m-1">
                Buy Now
              </Link>
              <button className="btn btn-dark m-1">Add to Cart</button>
            </div>
          </div>
        </div> */}
      </>
    );
  };
  return (
    <>
      <div className="container my-3 py-3">
        <div className="row">
          <div className="col-12">
            <h2
              className="display-5 text-center"
              style={{ fontWeight: "bold" }}
            >
              <label>Nos Produits</label>
            </h2>
            <hr />
            <p
              className="text-center"
              style={{ margin: "20px 0", fontSize: "1.2rem" }}
            >
              Découvrez notre gamme complète de produits sur{" "}
              <strong>DS Energy Pro</strong>, spécialement conçus pour répondre
              à tous vos besoins en matière de fitness. Que vous soyez un
              athlète chevronné ou un débutant, nos produits de qualité
              supérieure vous aideront à optimiser vos performances et à
              atteindre vos objectifs de santé.
            </p>
            <p
              className="text-center"
              style={{ margin: "20px 0", fontSize: "1.2rem" }}
            >
              Explorez nos catégories de protéines, gainers, et compléments
              alimentaires, tous formulés pour vous fournir l'énergie et les
              nutriments nécessaires pour exceller lors de vos entraînements.
              Sur <strong>DS Energy Pro</strong>, nous nous engageons à vous
              offrir les meilleurs suppléments pour soutenir votre parcours de
              fitness.
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          {loading || filter.length === 0 ? <Loading /> : <ShowProducts />}
        </div>
      </div>
    </>
  );
};

export default Products;
