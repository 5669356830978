import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";
import { message, Progress } from "antd";
import { useDispatch } from "react-redux";
import * as authActions from "../redux/actions/Inscription/index";
import { FaEnvelope, FaLock, FaUserAlt } from "react-icons/fa"; // Icons for email, password, and name
import { BsCheckCircle, BsDashCircle } from "react-icons/bs"; // React Icon for check circle
import { motion } from "framer-motion"; // Smooth animations for buttons

const Register = () => {
  const dispatch = useDispatch();
  const [confirmed, setConfirmed] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1200);
  const [checked, setChecked] = useState("");
  const [password_Strength, setPassword_Strength] = useState(0);
  const [number, setNumber] = useState(false);
  const [special, setSpecial] = useState(false);
  const [characters, setCharacters] = useState(false);
  const [majuscul, setMajuscul] = useState(false);
  const [emailValide, setEmailValide] = useState("");
  const [passwordValide, setPasswordValide] = useState("");

  const addUser = async (data, email, password) => {
    const x = await dispatch(
      authActions.inscription({ data, email, password })
    );
    if (x) {
      message.info("Consulter votre boite mail pour terminer!", 10);
      setConfirmed(true);
    }
  };

  const handleAddUser = () => {
    if (checked !== true) {
      setChecked(false);
    }
    if (emailValide !== true) {
      setEmailValide(false);
    }
    if (!number || !special || !characters || !special) {
      setPasswordValide(false);
    }
    if (!emailValide) {
      message.error("Adresse e-mail invalide");
    } else if (!number || !special || !characters || !special) {
      message.error("Mot de passe est faible");
    } else {
      addUser(name, email, password);
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const isValidPassword = (password) => {
    if (
      number === true &&
      special === true &&
      characters === true &&
      special === true
    ) {
      setPasswordValide(true);
    } else {
      setPasswordValide(false);
      message.warning("Veuillez vérifier votre mot de passe");
    }
  };

  const isValidEmailAddress = (address) => {
    if (!/\S+@\S+\.\S+/.test(address)) {
      message.warning("Veuillez vérifier votre adresse e-mail");
      setEmailValide(false);
    } else {
      setEmailValide(true);
    }
  };

  const checkStrength = (password) => {
    let strength = 0;

    if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
      strength += 1;
      setMajuscul(true);
    } else {
      setMajuscul(false);
    }
    if (password.match(/([0-9])/)) {
      strength += 1;
      setNumber(true);
    } else {
      setNumber(false);
    }
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      strength += 1;
      setSpecial(true);
    } else {
      setSpecial(false);
    }
    if (password.length > 7) {
      strength += 1;
      setCharacters(true);
    } else {
      setCharacters(false);
    }

    if (strength === 0) {
      setPassword_Strength(0);
    } else if (strength === 1) {
      setPassword_Strength(25);
    } else if (strength === 2) {
      setPassword_Strength(50);
    } else if (strength === 3) {
      setPassword_Strength(75);
    } else if (strength === 4) {
      setPassword_Strength(100);
    }
  };

  useEffect(() => {
    checkStrength(password);
  }, [password]);

  return (
    <>
      <Navbar />
      <div className="container my-5 py-3">
        <h1 className="text-center mb-4 text-warning">Register</h1>
        <motion.div
          className="row my-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="col-md-8 col-lg-6 col-sm-10 mx-auto shadow-lg p-5 rounded-lg">
            <form>
              <div className="my-3">
                <label className="form-label">Name</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaUserAlt />
                  </span>
                  <input
                    type="text"
                    className="form-control rounded-pill"
                    id="Name"
                    placeholder="Enter Your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="my-3">
                <label className="form-label">Email Address</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaEnvelope />
                  </span>
                  <input
                    type="email"
                    className="form-control rounded-pill"
                    id="Email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => isValidEmailAddress(email)}
                  />
                </div>
              </div>
              <div className="my-3">
                <label className="form-label">Password</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    className="form-control rounded-pill"
                    id="Password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => isValidPassword(password)}
                  />
                </div>
              </div>
              <div>
                <Progress
                  status={
                    password_Strength < 30
                      ? "exception"
                      : password_Strength < 80
                      ? "active"
                      : ""
                  }
                  showInfo={password_Strength === 100 ? true : false}
                  percent={password_Strength}
                />
              </div>

              <ul className="list-unstyled">
                <li className="d-flex align-items-center">
                  {majuscul === true ? (
                    <BsCheckCircle style={{ color: "#02b502" }} />
                  ) : (
                    <BsDashCircle style={{ color: "#b3b3b3" }} />
                  )}
                  &nbsp;Minuscule &amp; Majuscule
                </li>
                <li className="d-flex align-items-center">
                  {number === true ? (
                    <BsCheckCircle style={{ color: "#02b502" }} />
                  ) : (
                    <BsDashCircle style={{ color: "#b3b3b3" }} />
                  )}
                  &nbsp;Chiffre (0-9)
                </li>
                <li className="d-flex align-items-center">
                  {special === true ? (
                    <BsCheckCircle style={{ color: "#02b502" }} />
                  ) : (
                    <BsDashCircle style={{ color: "#b3b3b3" }} />
                  )}
                  &nbsp;Caractères Spéciaux (!@#$%^&*)
                </li>
                <li className="d-flex align-items-center">
                  {characters === true ? (
                    <BsCheckCircle style={{ color: "#02b502" }} />
                  ) : (
                    <BsDashCircle style={{ color: "#b3b3b3" }} />
                  )}
                  &nbsp;Au moins 8 caractères
                </li>
              </ul>

              <div className="my-3">
                <p>
                  Already have an account?{" "}
                  <Link
                    to="/login"
                    className="text-decoration-underline text-warning"
                  >
                    Login
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <motion.button
                  className="my-2 mx-auto btn btn-warning rounded-pill px-4 py-2"
                  type="button"
                  onClick={() => handleAddUser()}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Register
                </motion.button>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
