import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import * as loginActions from "../redux/actions/Inscription/index";
import { useDispatch } from "react-redux";
import { FaEnvelope, FaLock } from "react-icons/fa"; // Icons for email and password
import { motion } from "framer-motion"; // Smooth animations for buttons

const Login = () => {
  const [checked, setChecked] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [password, setPassword] = useState(localStorage.getItem("password"));

  const login = async (email, password) => {
    if (checked === true) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    }
    var someDate = new Date();
    someDate.setHours(someDate.getHours() + 15);
    localStorage.setItem("available", someDate);
    const response = await dispatch(loginActions.login({ email, password }));
    if (response) navigate("/");
  };

  const handleLogin = () => {
    login(email, password);
  };

  return (
    <>
      <Navbar />
      <div className="container my-5 py-3">
        <h1 className="text-center mb-4 text-warning">Login</h1>
        <motion.div
          className="row my-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <div className="col-md-8 col-lg-6 col-sm-10 mx-auto shadow-lg p-5 rounded-lg">
            <form>
              <div className="my-3">
                <label className="form-label">Email Address</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaEnvelope />
                  </span>
                  <input
                    type="email"
                    className="form-control rounded-pill"
                    id="floatingInput"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="my-3">
                <label className="form-label">Password</label>
                <div className="input-group">
                  <span className="input-group-text">
                    <FaLock />
                  </span>
                  <input
                    type="password"
                    className="form-control rounded-pill"
                    id="floatingPassword"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="my-3 d-flex justify-content-between align-items-center">
                <div>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    className="form-check-input"
                  />
                  <label className="form-check-label">Remember me</label>
                </div>
                <p>
                  New here?{" "}
                  <Link
                    to="/register"
                    className="text-decoration-underline text-warning"
                  >
                    Register
                  </Link>{" "}
                </p>
              </div>
              <div className="text-center">
                <motion.button
                  className="my-2 mx-auto btn btn-warning rounded-pill px-4 py-2"
                  type="button"
                  onClick={() => handleLogin()}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Login
                </motion.button>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
