import Axios from "axios";
import { message } from "antd";

export const getProduit = async () => {
  //await delay(1000)
  const result = await Axios.get(
    "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produits"
  );
  return result.data.produit;
};

export const addProduit = async (produit) => {
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produit",
      produit,
      {
        headers: headers,
      }
    );

    message.success("Le produit a été ajouté avec succès");
    return result.data;
  } catch (err) {
    if (err.response.status === 409) {
      message.error(
        "Un produit avec ce nom est déjà enregistré dans notre système.",
        [10]
      );
      return 409;
    } else message.error("Une erreur s'est produite, réessayer plus tard.");
  }
};

export const deleteProduit = async (user) => {
  try {
    const result = await Axios.post(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/deleteproduit/" +
        user._id
    );
    message.success("Le produit a été supprimé avec succès");
    return result.data;
  } catch (err) {
    message.error("Une erreur s'est produite, réessayer plus tard");
  }
};

export const fetchProduitById = async (id) => {
  const result = await Axios.get(
    "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produit/" +
      id
  );
  return result.data.produit;
};

export const updateProduit = async (id, Produit, token) => {
  try {
    const result = await Axios.put(
      "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/produit/" +
        id,
      { Produit },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );

    message.success("Le produit a été mis à jour avec succès");
    return result.data;
  } catch (err) {
    message.error("Une erreur s'est produite, réessayer plus tard");
  }
};

export const addCenterProduit = async (produit) => {
  const result = await Axios.post(
    "https://oscum884la.execute-api.us-east-1.amazonaws.com/dev/dsenergypro/ProduitCenter",
    produit
  );
  return result.data;
};
