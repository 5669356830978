import * as types from "../../types/types";
import * as api from "../../../services/produit.service";

export const fetchProduits = () => async (dispatch) => {
  dispatch({
    type: types.FETCH_PRODUIT_REQUEST,
  });
  try {
    const tasks = await api.getProduit();
    dispatch({
      type: types.FETCH_PRODUIT_SUCCESS,
      tasks,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: types.FETCH_PRODUIT_FAILURE,
    });
  }
};
export const addProduit = (produit) => async (dispatch) => {
  const newProduit = await api.addProduit(produit);
  dispatch({
    type: types.ADD_PRODUIT,
    produit: newProduit,
  });
};
export const deleteProduit = (user) => async (dispatch) => {
  await api.deleteProduit(user);
  const id = user._id;
  dispatch({
    type: types.DELETE_PRODUIT,
    id,
  });
};
export const fetchProduitById = (id) => async (dispatch) => {
  const produit = await api.fetchProduitById(id);
  dispatch({
    type: types.FETCH_PRODUIT_BY_ID,
    produit,
  });
  return produit;
};
export const updateProduit = (id, produit, token) => async (dispatch) => {
  const updatedProduit = await api.updateProduit(id, produit, token);
  dispatch({
    type: types.UPDATE_PRODUIT,
    id,
    produit: updatedProduit,
  });
};

export const addCenterProduit = (produit) => async (dispatch) => {
  const newProduit = await api.addCenterProduit(produit);

  dispatch({
    type: types.ADD_CENTER_PRODUIT,
    produit: newProduit,
  });
};
