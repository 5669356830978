import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useDispatch, useSelector } from "react-redux";
import { addCart } from "../redux/actions";
import { fetchProduitById } from "../redux/actions/Produit";
import { FaShoppingCart, FaMoneyBillWave, FaInfoCircle } from "react-icons/fa";

import { Footer, Navbar } from "../components";

const Product = () => {
  const { id } = useParams();
  const produits = useSelector((state) => state.produits);

  const [product, setProduct] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const dispatch = useDispatch();

  const addProduct = (product) => {
    dispatch(addCart(product));
  };

  useEffect(() => {
    const getProduct = async () => {
      setLoading(true);
      setLoading2(true);
      const produit = produits.list.find((product) => product._id === id);
      if (produit) {
        setProduct(produit);
        setLoading(false);
        setSimilarProducts(
          produits.list.filter((item) => item.categorie === produit.categorie)
        );
        setLoading2(false);
      } else {
        const produitId = await dispatch(fetchProduitById(id));
        console.log("here", produitId);

        setProduct(produitId);
        setLoading(false);
        // setSimilarProducts(
        //   produits.list.filter((item) => item.categorie === produit.categorie)
        // );
        setLoading2(false);
      }
    };
    getProduct();
  }, [id]);

  // Array to hold star icons
  const stars = [];

  // Loop to push star icons into the array
  for (let i = 0; i < 5; i++) {
    stars.push(<i className="fa fa-star gold-star"></i>);
  }

  const Loading = () => {
    return (
      <>
        <div className="container my-5 py-2">
          <div className="row">
            <div className="col-md-6 py-3">
              <Skeleton height={400} width={400} />
            </div>
            <div className="col-md-6 py-5">
              <Skeleton height={30} width={250} />
              <Skeleton height={90} />
              <Skeleton height={40} width={70} />
              <Skeleton height={50} width={110} />
              <Skeleton height={120} />
              <Skeleton height={40} width={110} inline={true} />
              <Skeleton className="mx-3" height={40} width={110} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowProduct = () => {
    const descriptionSegments =
      product && product.description?.replace(/\./g, ".<br><br>");

    return (
      <>
        {product && (
          <div className="container my-5 py-2">
            <div className="row">
              <div className="col-md-6 col-sm-12 py-3">
                <img
                  className="img-fluid"
                  src={product.url}
                  alt={product.name}
                  width="500px"
                  height="400px"
                />
              </div>
              <div className="col-md-6 col-md-6 py-5">
                <h4 className="text-uppercase text-muted">
                  {product.categorie}
                </h4>
                <h1 className="display-5">{product.name}</h1>
                <p className="lead">
                  {product.rating && product.rating.rate} {stars}
                </p>
                <h3 className="display-6  my-4">{product.prix} TND</h3>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{ __html: descriptionSegments }}
                ></p>
                <button
                  className="btn btn-outline-dark"
                  onClick={() => addProduct(product)}
                >
                  Ajouter au panier
                </button>
                <Link to="/cart" className="btn btn-dark mx-3">
                  Aller au panier
                </Link>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const Loading2 = () => {
    return (
      <>
        <div className="my-4 py-4">
          <div className="d-flex">
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
            <div className="mx-4">
              <Skeleton height={400} width={250} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const ShowSimilarProduct = () => {
    return (
      <>
        <div className="py-4 my-4">
          <div className="d-flex">
            {similarProducts.map((item) => {
              return (
                <div key={item._id} className="card mx-4 text-center">
                  <img
                    className="card-img-top p-3"
                    src={item.url}
                    style={{ height: 300 }}
                    alt="Card"
                  />
                  <div className="card-body">
                    <h5 className="card-title">
                      {item.name.substring(0, 15)}...
                    </h5>
                  </div>
                  {/* <ul className="list-group list-group-flush">
                    <li className="list-group-item lead">${product.price}</li>
                  </ul> */}
                  <div className="card-body d-flex flex-wrap justify-content-start">
                    <Link
                      to={"/product/" + item._id}
                      className="btn btn-dark m-1 white-ss d-flex align-items-center"
                    >
                      <FaMoneyBillWave
                        className="me-2"
                        style={{ color: "lime" }}
                      />{" "}
                      {/* Replace FaBuy with the appropriate icon */}
                      Acheter
                    </Link>
                    <button
                      className="btn btn-dark m-1 white-ss d-flex align-items-center"
                      onClick={() => addProduct(item)}
                    >
                      <FaShoppingCart className="me-2" />
                      Ajouter au panier
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">{loading ? <Loading /> : <ShowProduct />}</div>
        <div className="row my-5 py-5">
          <div className="d-none d-md-block">
            <h2 className="">Vous pourriez également aimer</h2>
            <Marquee pauseOnHover={false} pauseOnClick={true} speed={50}>
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
